export const data = [
  { value: "070010", label: "ABBEY MORTGAGE BANK-070010" },
  { value: "044", label: "ACCESS BANK PLC-044" },
  { value: "323", label: "AccessMobile-100013" },
  { value: "090134", label: "ACCION MICROFINANCE BANK-090134" },
  { value: "090160", label: "ADDOSSER MICROFINANCE BANK-090160" },
  { value: "100028", label: "AG MORTGAGE BANK PLC-100028" },
  { value: "090133", label: "AL-BARAKAH MICROFINANCE BANK-090133" },
  { value: "090180", label: "AMJU UNIQUE MICROFINANCE BANK-090180" },
  { value: "090116", label: "AMML MFB-090116" },
  { value: "401", label: "ASO SAVINGS AND LOANS-401" },
  { value: "090117", label: "Boctrust Microfinance Bank-090117" },
  { value: "090148", label: "BOWEN MICROFINANCE BANK-090148" },
  { value: "070015", label: "BRENT MORTGAGE BANK-070015" },
  { value: "317", label: "CELLULANT-317" },
  { value: "090154", label: "CEMCS MICROFINANCE BANK-090154" },
  { value: "001", label: "CENTRAL BANK OF NIGERIA-001" },
  { value: "303", label: "ChamsMobile-303" },
  { value: "090144", label: "CIT MICROFINANCE BANK-090144" },
  { value: "023", label: "Citibank Nigeria LTD-023" },
  { value: "090130", label: "CONSUMER MICROFINANCE BANK-090130" },
  { value: "100032", label: "CONTEC GLOBAL INFOTECH LIMITED (NOWNOW)-100032" },
  { value: "559", label: "Coronation Merchant Bank-559" },
  { value: "551", label: "Covenant -551" },
  { value: "090159", label: "CREDIT AFRIQUE MICROFINANCE BANK-090159" },
  { value: "090167", label: "DAYLIGHT MICROFINANCE BANK -090167" },
  { value: "063", label: "DIAMOND BANK PLC-063" },
  { value: "302", label: "Eartholeum-100021" },
  { value: "050", label: "ECOBANK NIGERIA PLC-050" },
  { value: "307", label: "EcoMobile-307" },
  { value: "562", label: "EKONDO MICROFINANCE BANK -562" },
  { value: "090114", label: "EMPIRE TRUST BANK-090114" },
  { value: "306", label: "eTranzact-306" },
  { value: "090179", label: "FAST MICROFINANCE BANK-090179" },
  { value: "413", label: "FBN Mortgages Limited -413" },
  { value: "309", label: "FBNMobile-309" },
  { value: "060002", label: "FBNQuest Merchant Bank Limited-060002" },
  { value: "314", label: "FET-314" },
  { value: "090153", label: "FFS MICROFINANCE BANK-090153" },
  { value: "070", label: "FIDELITY BANK PLC-070" },
  { value: "318", label: "Fidelity Mobile-100019" },
  { value: "090126", label: "FidFund MFB-090126" },
  { value: "608", label: "FINATRUST MICROFINANCE BANK-608" },
  { value: "011", label: "FIRST BANK OF NIGERIA PLC-011" },
  { value: "214", label: "FIRST CITY MONUMENT BANK PLC-214" },
  { value: "090164", label: "FIRST ROYAL MICROFINANCE BANK-090164" },
  { value: "110002", label: "FLUTTERWAVE TECHNOLOGY SOLUTIONS LIMITED-110002" },
  { value: "308", label: "Fortis Microfinance Bank-070002" },
  { value: "601", label: "FSDH MERCHANT BANK LIMIT-601" },
  { value: "070009", label: "GATEWAY MORTGAGE BANK-070009" },
  { value: "090122", label: "GOWANS MFB-090122" },
  { value: "090178", label: "GREENBANK MICROFINANCE BANK-090178" },
  { value: "315", label: "GTMobile-100009" },
  { value: "058", label: "GTBank" },
  { value: "070017", label: "Haggai Mortgage Bank Limited-070017" },
  { value: "090121", label: "HASAL MICROFINANCE BANK-090121" },
  { value: "324", label: "Hedonmark-100017" },
  { value: "030", label: "HERITAGE BANK -030" },
  { value: "090175", label: "HIGHSTREET MICROFINANCE BANK-090175" },
  { value: "090118", label: "IBILE MFB-090118" },
  { value: "415", label: "IMPERIAL HOMES MORTGAGE BANK-415" },
  { value: "090157", label: "INFINITY MICROFINANCE BANK-090157" },
  { value: "070016", label: "INFINITY TRUST MORTGAGE BANK-070016" },
  { value: "100029", label: "Innovectives Kesh-100029" },
  { value: "100027", label: "Intellifin-100027" },
  { value: "301", label: "JAIZ BANK PLC-301" },
  { value: "402", label: "JUBILEE LIFE MORTGAGE BANK-402" },
  { value: "082", label: "KEYSTONE BANK-082" },
  { value: "100025", label: "KongaPay-100025" },
  { value: "070012", label: "LAGOS BUILDING INVESTMENT COMPANY-070012" },
  { value: "090174", label: "MALACHY MICROFINANCE BANK-090174" },
  { value: "090136", label: "Microcred Microfinance Bank Nigeria Ltd-090136" },
  { value: "090113", label: "MICROVIS MICROFINANCE BANK-090113" },
  { value: "313", label: "Mkudi-100011" },
  { value: "50211", label: "Kuda Microfinance Bank" },
  { value: "090129", label: "MONEY TRUST MICROFINANCE BANK-090129" },
  { value: "325", label: "MoneyBox -100020" },
  { value: "090151", label: "MUTUAL TRUST MICROFINANCE BANK-090151" },
  { value: "090128", label: "NDIORAH MICROFINANCE BANK -090128" },
  { value: "561", label: "New Prudential Bank-561" },
  { value: "907", label: "NIGERIA INTERBANK SETTLEMENT SYSTEM-907" },
  { value: "999", label: "NIP Virtual Bank -999" },
  { value: "060003", label: "NOVA MERCHANT BANK LTD-060003" },
  { value: "552", label: "NPF Microfinance Bank -552" },
  {
    value: "090119",
    label: "OHAFIA MFB-090119",
  },
  {
    value: "090161",
    label: "OKPOGA MICROFINANCE BANK-090161",
  },
  {
    value: "606",
    label: "Omoluabi Savings and Loans Plc-606",
  },
  {
    value: "100026",
    label: "ONE FINANCE-100026",
  },
  {
    value: "327",
    label: "PAGA-327",
  },
  {
    value: "560",
    label: "Page MFBank-560",
  },
  {
    value: "526",
    label: "PARALLEX MFB-526",
  },
  {
    value: "311",
    label: "Parkway-ReadyCash-100003",
  },
  {
    value: "329",
    label: "PayAttitude Online -329",
  },
  {
    value: "305",
    label: "PAYCOM (Opay)-305",
  },
  {
    value: "090137",
    label: "PECANTRUST MICROFINANCE BANK-090137",
  },
  {
    value: "090135",
    label: "Personal Trust Microfinance Bank-090135",
  },
  {
    value: "090165",
    label: "PETRA MICROFINANCE BANK-090165",
  },
  {
    value: "070013",
    label: "PLATINUM MORTGAGE BANK-070013",
  },
  {
    value: "076",
    label: "POLARIS BANK LTD (SKYE BANK PLC)-076",
  },
  {
    value: "090170",
    label: "RAHAMA MICROFINANCE BANK-090170",
  },
  {
    value: "502",
    label: "RAND MERCHANT BANK-502",
  },
  {
    value: "090125",
    label: "REGENT MFB-090125",
  },
  {
    value: "090173",
    label: "RELIANCE MICROFINANCE BANK-090173",
  },
  {
    value: "101",
    label: "PROVIDUS BANK-101",
  },
  {
    value: "090132",
    label: "RICHWAY MICROFINANCE BANK-090132",
  },
  {
    value: "403",
    label: "Safetrust Mortgage bank -403",
  },
  {
    value: "090140",
    label: "SAGAMU MICROFINANCE BANK LTD-090140",
  },
  {
    value: "609",
    label: "SEED CAPITAL MICROFINANCE BANK-609",
  },
  {
    value: "99",
    label: "",
  },
  {
    value: "100",
    label: "SunTrust Bank-100",
  },
  { value: "221", label: "STANBIC IBTC BANK PLC-221" },
  { value: "304", label: "STANBIC MOBILEMONEY-100007" },
  { value: "068", label: "STANDARD CHARTERED BANK NIGERIA LTD-068" },
  { value: "232", label: "STERLING BANK PLC-232" },
  { value: "326", label: "Sterling Mobile -100022" },
  { value: "090115", label: "TCF-090115" },
  { value: "319", label: "TeasyMobile-100010" },
  { value: "090146", label: "TRIDENT MICROFINANCE BANK-090146" },
  { value: "523", label: "TRUSTBOND MORTGAGE BANK-523" },
  { value: "032", label: "UNION BANK OF NIGERIA PLC-032" },
  { value: "033", label: "UNITED BANK FOR AFRICA PLC-033" },
  { value: "215", label: "UNITY BANK PLC-215" },
  { value: "090123", label: "Verite Microfinance Bank-090123" },
  { value: "090110", label: "VFD MICROFINANCE BANK-090110" },
  { value: "090139", label: "VISA MICROFINANCE BANK-090139" },
  { value: "328", label: "Visual ICT -100023" },
  { value: "320", label: "VTNetworks-100012" },
  { value: "035", label: "WEMA BANK PLC-035" },
  { value: "090120", label: "WETLAND MFB-090120" },
  { value: "090124", label: "XSLNCE Microfinance Bank-090124" },
  { value: "090142", label: "YES MICROFINANCE BANK-090142" },
  { value: "057", label: "ZENITH BANK PLC-057" },
  { value: "322", label: "ZenithMobile-100018" },
];

export const timee = [
  "12:00 am",
  "1:00 am",
  "2:00 am",
  "3:00 am",
  "4:00 am",
  "5:00 am",
  "6:00 am",
  "7:00 am",
  "8:00 am",
  "9:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 pm",
  "1:00 pm",
  "2:00 pm",
  "3:00 pm",
  "4:00 pm",
  "5:00 pm",
  "6:00 pm",
  "7:00 pm",
  "8:00 pm",
  "9:00 pm",
  "10:00 pm",
  "11:00 pm",
  "11:00 pm",
];
export const hourrs = [
  "1 hour",
  "2 hour",
  "3 hour",
  "4 hour",
  "5 hour",
  "6 hour",
  "7 hour",
  "8 hour",
  "9 hour",
  "10 hour",
  "11 hour",
  "12 hour",
  "12 hour",
];
